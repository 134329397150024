@import '../../Styles/settings';

.root {
  padding-top: $mobile-header-height + 30px;
}

.noSearchPhraseTitle {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 25px;
}

.tabs {
  --text-size: 14px;
  --button-padding: 16px 12px;

  @media #{$md-up} {
    --text-size: 18px;
    --button-padding: 16px;
  }

  display: flex;
  justify-content: flex-start;
  gap: 8px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  max-width: 100%;
  margin-left: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__button {
    color: $near-black;
    border: none;
    text-transform: none;
    padding: var(--button-padding);
    border-radius: 4px 4px 0px 0px;
    background-color: $light-gray;
    transition: background-color 0.25s ease;
    font-size: var(--text-size);
    white-space: nowrap;
    cursor: pointer;
    @include HelveticaMedium;

    &--active {
      &:not(:disabled) {
        background-color: $bona-light-blue;
      }
    }

    &:disabled {
      opacity: 0.5;
    }
  }
}

.higlightText {
  font-weight: 500;
}

.search-page-top {
  --content-column-size: span 12;

  @media #{$md-up} {
    --content-column-size: span 9;
  }

  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  &__placeholder {
    grid-column-end: span 3;
  }

  &__content {
    grid-column-end: var(--content-column-size);
  }

  &__input-container {
    margin-bottom: 32px;
    margin-right: 8px;

    > div {
      margin: 0 !important;
    }
  }
}

@media #{$md-up} {
  .root {
    padding-top: $standard-header-height-md-up + 70px;
  }

  .noSearchPhraseTitle {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .tabs {
    padding: 0;
    width: auto;
  }
}
