@import '../../Styles/settings';

.root {
  text-align: center;
  margin-bottom: 32px;
  @include styleComponent('style_id_didYouMean_textBtn') {
    display: inline-flex;
    text-transform: none;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.searchResultPresentation {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 25px;
}

.searchPhrase {
  font-weight: 600;
  padding-left: 10px;
}

.helper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 16px;

  button:not(:last-of-type) {
    margin-right: 20px;
  }

  button span {
    font-weight: 500;
    margin-bottom: 10px;
  }
}

.helperHeader {
  margin-bottom: 8px;
  font-size: 10px;
  color: $dark-gray;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  @include HelveticaMedium;
}

.helperLinks {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  flex-direction: column;
  white-space: nowrap;
}

.category {
  text-decoration: none;
  margin-bottom: 10px;

  span {
    font-weight: bold;
  }

  span:not(:last-of-type) {
    margin-right: 20px;
  }
}

@media #{$md-up} {
  .root {
    margin-bottom: 32px;
  }
  .searchResultPresentation {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .helperContainer {
    display: flex;
    flex-direction: row;
  }

  .helper {
    justify-content: left;
    &:first-of-type:not(:empty) {
      margin-right: 48px;
    }
  }

  .helperLinks {
    flex-direction: row;
  }

  .category {
    text-transform: none;
    text-decoration: none;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}
