@import '../../../Styles/settings';

.no-results {
  --heading-size: 20px;
  --header-margin: 16px;
  --top-block-padding: 24px 16px 48px 16px;
  --bottom-block-padding: 48px 16px 24px 16px;

  @media #{$md-up} {
    --heading-size: 24px;
    --header-margin: 24px;
    --top-block-padding: 32px 24px 48px 24px;
    --bottom-block-padding: 48px 24px 32px 24px;
  }

  padding: 48px 0;

  &__inner {
    max-width: 552px;
    margin: 0 auto;
    background-color: $white;
    box-shadow: $bona-box-shadow;
    border-radius: 8px;
  }

  &__top {
    padding: var(--top-block-padding);
    border-bottom: 1px solid $bona-light-grey;
  }

  &__heading {
    font-size: var(--heading-size);
    color: $near-black;
    margin-bottom: var(--header-margin);
  }

  &__bullet-list {
    ul {
      padding-left: 24px;
    }

    li {
      color: $darkest-gray;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__bottom {
    padding: var(--bottom-block-padding);
  }

  &__help-label {
    color: $near-black;
    line-height: 20px;
    font-size: 16px;
    @include HelveticaMedium;
    display: block;
    margin-bottom: 24px;
  }

  &__contact-container {
    display: flex;
    gap: 24px;
  }

  &__contact-info {
    color: $color-brand;
    font-size: 14px;
    @include HelveticaMedium;
    display: inline-flex;
    align-items: center;
    text-decoration: none;

    svg {
      stroke: $light-blue;
    }
  }
}
