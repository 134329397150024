@import '../../../Styles/settings';

.search-input-basic {
  --input-width: 100%;

  @media #{$md-up} {
    --input-width: 555px;
  }

  color: $near-black;
  width: 100%;
  max-width: var(--input-width);
  margin: 0 auto;
  position: relative;

  @include styleComponent('style_id_search_SearchIconBtn') {
    top: 0px;
    right: 0;
    position: absolute;
  }

  input {
    background-color: $white;
    color: currentColor;
    padding: 0 16px;
    border: 1px solid $near-black;

    &:focus {
      border: 1px solid $near-black;

      + label {
        color: currentColor;
      }
    }
  }

  label {
    background-color: $white;
    color: currentColor;
  }

  svg path {
    stroke: $near-black;
  }
}
